const app = new Vue({
  el: '#primary',
  components: {
    Hooper: window.Hooper.Hooper,
    Slide: window.Hooper.Slide,
  },
  data: {
    hooperSettings: {
      autoPlay: true,
      trimWhiteSpace: true,
      playSpeed: 5000,
      transition: 500,
      infiniteScroll: true,
      centerMode: true,
      itemsToShow: 1,
      wheelControl: false,
      breakpoints: {
        800: { itemsToShow: 1.5 },
      },
    },
  },
})
